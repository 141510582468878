import { styled} from "styled-components";
const Wrapper = styled.div`
  width:75vw;
  padding-top: 50px;
  padding-bottom: 35px;
  display:flex;
  flex-direction:column;
  align-items:center;
  font-size: 1.2rem;
  overflow:hidden;
  @media only screen and (max-width: 1024px){
    width:80vw;
  }
 h1{
  padding-top:35px;
 }
 li{
  list-style:circle;
 }
`

const Link = styled.div`
display:flex;
flex-direction:column;
align:center;
text-align:center;
margin:10px;
button{
  padding:10px;
  margin-top:10px;
  font-size: 1.2rem;
  background:var(--mainColor) ;
  color:white;
  border-radius:15px;
  
}
`
const Text =styled.h1`
text-align:center;
`
function info(){
    return(
        <Wrapper>
           <p>
           Cu o experiență de peste 35 de ani, Ninel se deplasează în tot Municipiul București oferind sevicii de: <br/></p>
          <br/>
          <ul>
            <li>
            reparații ,întreținere, verificări și montaj la instalațiile sanitare, instalațiile de gaze, termice și de climatizare
            </li><br/>
             <li>
             chiuvete de bucătărie, lavoare și baterii
             </li><br/>
             <li>
             vas wc, bazin apă wc, wc suspendat
             </li><br/>
             <li>
             echipamente de filtrare a apei
             </li><br/>
             <li>
             robineți de trecere
             </li><br/>
             <li>
             cabina dus, cadă hidromasaj, cazi de dus și cazi de baie
             </li><br/>
             <li>
             conductele de alimentare și canalizare
             </li><br/>
             <li>
             montaj și instalare mașină de spălat
             </li><br/>
             <li>
             orice fel de instalație sanitară baie
             </li>
           </ul>
           <br/>
           Și multe altele. Sună-l pe Ninel și întreabă, sigur v-a știi ce e de făcut și va veni rapid.
           
           <Text>
            Interesați și de altceva?
           </Text>
           <Link>
           <p>Ninel este priceput și în lucru manual. Are Măști și Toteme în stil african făcute manual din materiale bio și ecologice, perfecte pentru un cadou deosebit sau decorațiune speciale.</p>
            <a href="./Toteme">
           <button type="button" >Masti si Toteme</button>
           </a>
           </Link>

        </Wrapper>

    )


}

export default info