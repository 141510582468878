import { MapContainer, TileLayer, useMap, Marker, Popup,Circle } from 'react-leaflet'
import styled from "styled-components";

const Wrapper =styled.div`
overflow:hidden;
.leaflet-container {
  width: 60vw;
  height: 60vh;
  margin:20px;
  
  @media only screen and (max-width: 1024px){
    width: 98vw;
  height: 60vh; }
  margin:0;
}
`

function Map () {
    const fillBlueOptions = {color:"rgb(122,253,22,5)"}
    return(
        
        <Wrapper id="map">
        <MapContainer center={[44.427602, 26.102461]} zoom={11} scrollWheelZoom={true}>
  <TileLayer
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  <Circle center={[44.427602, 26.102461]} pathOptions={fillBlueOptions} radius={8500}>
    </Circle>
  <Marker position={[44.427602, 26.102461]}>
    <Popup>
      Bucuresti 
    </Popup>
  </Marker>
</MapContainer>
      </Wrapper>
    )
}

export default Map