import React from "react";
import Navbar from "./Components/nav";
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
import Principal from "./Pages/Principal.js";
import "./Styles/main.css";
import Footer from "./Components/footer";
import Toteme from "./Pages/Toteme.js";
function App() {
  return (  
      <Router>
       <Navbar/>
        <Routes>

       <Route exact path='/' element={<Principal />} />
       <Route path='/toteme' element={<Toteme />} />

        </Routes>
      
       
       <Footer></Footer>
      
      
       </Router>
      
      

  );
}

export default App;
