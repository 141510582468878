import styled from 'styled-components';
import background from'../Img/background.webp'
import Totem from '../Components/Totem';
import Pdf from '../Img/MastiToteme.pdf'
import { Helmet } from 'react-helmet';
const Page = styled.div`
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url(${background}) ;
  background-size: cover;
  background-repeat: repeat-y;
  height:100%;
  width:100vw ;
  overflow:hidden;

`
const Info = styled.div`
display: flex ;
flex-direction: column;
align-items:center;
padding:35px;
margin:25px;
background-color:rgba(255,255,255, 0.8) ;
text-align:center;

button{
  padding:10px;
  margin:10px;
  font-size: 1.2rem;
  background:var(--mainColor) ;
  color:white;
  border-radius:15px;
}
`

function Despre () {

    return(

   <Page>
    <Helmet>
    <meta charSet="utf-8" />
    <title>Toteme si Masti</title>
          <link rel="canonical" href="https://ninel-instalatorul.ro/Toteme" />
          <meta
      name="description"
      content=" Masti si Toteme concepute din diverse materiale, special pentru un cadou sau ca si decoratiuni"
    />
      <script type='application/ld+json'>
      {JSON.stringify({
  "@context": "http://schema.org/",
  "telephone": "0756 402 708",
  "name":"Ninel Instalatorul - Toteme si Masti in stil African",
  "@type": "Plumber", 
  "address":{
    "@type": "PostalAddress",
    "addressLocality": "Bucharest"},
 "image":"ninel",
  "employee": {
    "@type": "Person",
    "name": "Ninel"
  },
  "description":"Masti si Toteme concepute din diverse materiale, special pentru un cadou sau ca si decoratiuni ",
  "areaServed": {
    "@type": "City",
    "name": "Bucharest"
  },
  "openingHours": [
    "Mo-Sa 00:00-24:00"
  ],
  "priceRange": "$",
  "currenciesAccepted": "USD, RON, EUR",
  "keywords":"plumber, profesional, profesionist, instalatii sanitare, gaze, apa, non-stop, urgenta, rapid, canalizare, calorifer, boiler, instalator, profesional",
  "keyword":"plumber,  profesional, profesionist, instalatii sanitare, gaze, apa, non-stop, urgenta, rapid, canalizare, calorifer, boiler, instalator, profesional"
  
})}
      </script>
        </Helmet>
    <Info>
      <h1>
         Masti si Toteme in stil african
      </h1>
      <p style={{fontSize:'1.5rem',margin:'25px'}}>Ninel construieste si creeaza aceste masti si toteme in stil african manual, din materiale reciclate (pvc, lut, sfoara, carton) si pictate cu vospsele ecologice acrilice, imbinate cu aracet. Sunt concepute in special pentru a imbraca o sticla cadou sau o vaza cu flori. Acestea sunt un cadou aparte pentru cineva special, totul avand o suma modesta si accesibila </p>
    </Info>
    <Totem/>
    <Info>
      <p style={{fontSize:'1.5rem',margin:'25px'}}>
        Pentru mai multe exemple puteti descarca urmatorul fisier plin cu exemple si lucrari trecute: 
      </p> 
      <a href={Pdf} download="Masti-Toteme" target="_blank" rel="noopener noreferrer"><button>Descarca PDF</button></a>
    </Info>

   </Page>

    )
}

export default Despre

