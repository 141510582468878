import React from 'react';
import Slider from '../Components/slider';
import styled from 'styled-components';
import Boxes from '../Components/boxes';
import background from'../Img/background.webp';
import Info from '../Components/info'
import Map from '../Components/map'
import {Helmet} from "react-helmet"
const Page = styled.div`
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url(${background}) ;
  background-size: cover;
  background-repeat: repeat-y;
  height:100%;
  overflow:hidden;

`
const Inner = styled.div`
display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7) ;
  height: 100%;
  padding-top:80px;
  margin-left:15%;
  margin-right:15%;
  @media only screen and (max-width: 1024px){
    width: 100vw;
    height:100%;
    margin:0;
  }


`
const Btn = styled.button`
padding:10px;
  margin:10px;
  font-size: 1.8rem;
  background:var(--mainColor) ;
  color:white;
  border-radius:15px;

  a:link { text-decoration: none;
    color: white; }


a:visited { text-decoration: none;
  color: white; }


a:hover { text-decoration: none;
  color: white; }


a:active { text-decoration: none;
  color: white; }


`

function Prima (){
  const ldJson ={
    "@context": "http://schema.org/",
  "@type": "LocalBusiness",
  "telephone": "0756 402 708",
  "name":"Ninel Instalatorul - Instalator Bucuresti şi Ilfov Non Stop ",
  "address":{
    "@type": "PostalAddress",
    "addressLocality": "Bucharest"
  },
    "image": "https://ninel-instalatorul.ro/static/media/water.161eb908231726a5c19c.webp",
  "employee": {
    "@type": "Person",
    "name": "Ninel"
  },
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": {
      "@type": "DayOfWeek",
      "name": "NonStop",
      "openingHours": [
        "Mo-Sa 00:00-24:00"
      ],
    }},
  "description":"Ninel are o experienta de peste 35 de ani in domeniul instalatiilor sanitare, disponibil in tot Municipiul Bucuresti, fiind la apel si pentru urgente NonStop ",
  "areaServed": {
    "@type": "City",
    "name": "Bucharest"
  },
  "priceRange": "$",
  "currenciesAccepted": "USD, RON, EUR",
  "keywords":"instalatii sanitare, gaze, apa, non-stop, urgenta, rapid, canalizare, calorifer, boiler, instalator, profesional"
  }
    return (
    <Page>
      <Helmet>
      <meta charSet="utf-8" />
          <title>Ninel Instalatorul</title>
          <link rel="canonical" href="https://ninel-instalatorul.ro" />
          <meta
      name="description"
      content="Ninel are o experienta de peste 35 de ani in domeniul instalatiilor sanitare, disponibil in tot Municipiul Bucuresti, fiind la apel si pentru urgente NonStop "
    />
    
    <script type="application/ld+json">
  {JSON.stringify(ldJson)}
</script>
        
        </Helmet>
      <Inner>
        
        <h1 style={{paddingTop: '20px', paddingBottom:'20px', textAlign:'center' }}> Bine Ati Venit la Ninel Instalatorul 
        <p>Telefon Mobil/Whatsapp: 0756 402 708</p></h1>
        <Btn>
          <a href = "tel:+40756402708"> SUNATI ACUM</a>
        </Btn>
        <Slider/>
        <Boxes/>
        <Info/>
        <Map/>
        </Inner>
    </Page>
    )
};
  
export default Prima;