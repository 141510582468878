import { styled } from "styled-components";
const Wrapper = styled.div`
display:flex;
flex-direction: column;
justify-content:space-around;
background-color:var(--mainColor) ;
width:100%;
`
const Box = styled.div`

display:flex;
flex-direction:column;
align-items:center;
padding:15px;
color: white ;
`
function Footer (){

 return(
   <Wrapper>
     <Box>

     </Box>
     <Box>
        <p>Telefon Mobil/Whatsapp: 0756 402 708</p>
        <p>NonStop la urgente  </p>
        <p>Toteme ecologice</p>
     </Box>
     <Box>
        
     </Box>

   </Wrapper>

 )
}


export default Footer