
import totemData from "../Styles/totemData.json"
import "../Styles/main.css";
import styled from "styled-components";


const Container= styled.div`
  display:flex;
  flex-wrap: wrap;
.image-box{
  position:relative;
  flex: 1 1 20%;
  height: 40vw;
  overflow: hidden;


.portofolio-image{
  position:absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


&:after{
  content:"";
  background: linear-gradient(180deg, #ffffff, #000);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top:0;
  width: 100%;
  height: 100%;
  z-index:2;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
    }

&:hover:after{
  opacity: 0.25;
}
&:hover .content{
  bottom:0;
}
  }



@media screen and(max-width: 1080px){
.container .portofolio-page{
width: 100%;
box-sizing: border-box;

.image-box{
  height: 200px;
  flex: 1 1 20%;
}
}
}

`
const Wrapper= styled.div`
  width:100%;
  position: initial;
  height: 100%;
  overflow: auto;
`

function Totem() {



    const renderTotem = (totem) =>{
        return (
          <Container>
                {
                    totem.map((totem,idx)=>{
                      return(
                       <div className ="image-box"  key={idx}>
                        <img
                        src={totem.cover} 
                        className='portofolio-image'
                        alt="totem"/>
                        
                        </div>
                      )
                    })
                }
            </Container>
        )
    }
    return(
       <Wrapper>
         <div className= "container portofolio-page">

            <div>{renderTotem(totemData.totem)}</div>
      </div>
      </Wrapper>




    )
}

export default Totem