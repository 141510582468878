import{useRef} from "react";
import{FaBars, FaTimes} from "react-icons/fa";
import "../Styles/main.css";
import Logo from '../Img/ninel logo.webp'
function Navbar(){
        const navRef = useRef();
        const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
        };
    
    return(
        <header>
           <img src={Logo} height={'90px'} background-color={'blue'} alt="Ninel Instalatorul"/>
           

            <nav ref={navRef}>
                <a href="/#" className="link">Prima Pagina</a>
                <a href="/Toteme" className="link">Masti si Toteme</a>
                <p>Telefon Mobil/Whatsapp: 0756 402 708</p>
             <button  className= "nav-btn nav-close-btn" onClick={showNavbar}>
               <FaTimes/>
             </button>
            </nav>

           <button  className= "nav-btn" onClick={showNavbar}>
            <FaBars/>
           </button>

        </header>
    );
}

export default Navbar;