
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import styled from 'styled-components';
import img1 from '../Img/water.webp'
import img2 from '../Img/gas.webp'
import img3 from '../Img/centrala.webp'
import img4 from '../Img/canalizare.webp'
import img5 from '../Img/calorifer.webp'
import img6 from '../Img/electrocasnice.webp'
const Wrapper = styled.div`
 padding-top: 20px;
 width:75%;
  @media only screen and (max-width: 1024px){
    width:98vw;
  }
 .carousel .slider-wrapper{
 }

 .carousel .thumb{
    width: 5%;
    height: 50px;
 }

 .carousel{
    text-align:center
 }
 .carousel .slide .legend {
    opacity:1;
    font-size:1.1rem;
 }
`
const Img = styled.div`
legend{

 }
`


function Slider(){
return(
    <Wrapper>
            <Carousel infiniteLoop useKeyboardArrows autoPlay interval={4500}>
                <Img >
                    <img src={img1}  alt="Conducte Apa"/>
                    <p className='legend'>Instalare conducte de apa</p>
                </Img>
                <Img>
                    <img src={img2} alt="Conducte Gas"/>
                    <p className='legend'>Instalare conducte gas si centrale</p>
                    
                </Img>
                <Img>
                    <img src={img3} alt="Boiler" />
                    <p className='legend'>Instalare Boiler</p>
                    
                </Img>
                <Img >
                    <img src={img4}  alt="Instalare Canalizare"/>
                    <p className='legend'>Instalare Canalizare</p>
                </Img>
                <Img >
                    <img src={img5}  alt="Instalare Calorifere"/>
                    <p className='legend'>Instalare Calorifere</p>
                </Img>
                <Img >
                    <img src={img6}  alt="Electrocasnice"/>
                    <p className='legend'>Electrocasnice</p>
                </Img>
            </Carousel>



    </Wrapper>



    
)



}

export default Slider