import styled from 'styled-components';
const Wrapper = styled.div`
 display: flex ;
 flex-direction:column;
 align-items:center;
 font-size: 1.2rem;
`

const Rapid = styled.div`
display:flex;
flex-direction: row;
padding:25px;
justify-content:space-around;
@media only screen and (max-width: 1024px){
   flex-direction:column;
   overflow:hidden;
}

`

const Info = styled.div`
display:flex;
flex-direction: column;
flex: 1 1 35%;
width:350px;
margin:15px;
justify-content:space-between;

`

const Inner = styled.div`
width: 900px;

  display: flex;
  flex:1 1 33%;
  flex-direction: row;
  flex-wrap:wrap;
  
  justify-content: space-evenly;
  @media only screen and (max-width: 1024px){
    flex-direction: column;
    align-items:center;
    width:100%;
  }
`
const Box = styled.div`
width: 30%;

display:flex;
flex-direction: column;
align-items:center;
justify-content:space-between;
padding: 20px;
background-color: white ;
margin:10px;
border-radius: 5px;
border-style:solid;
border-color: var(--mainColor);
@media only screen and (max-width: 1024px){
   width: 65vw;
}
`
const Title = styled.h2`
text-align:center;
`
const Text = styled.p`
margin:10px;
text-height:0.8rem;
`
function Boxes  (){
return(
   <Wrapper>
      <Rapid>
         <Info>
             <Title>
               RAPID SI ORIUNDE IN BUCURESTI - NON STOP
             </Title>
             <Text>
             Ninel este disponibil la orice ora, oriunde în București. Sună sau da mesaj și imediat Ninel îți sare în ajutor.
             </Text>
         </Info>
         <Info>
             <Title>
               PROFESIONALISM SI CALITATE GARANATATA
             </Title>
             <Text>
             Cu o experiență de peste 35 de ani și cu mulți clienți mulțumiți, Ninel va asigura cea mai bună lucrare lucrată cu cel mai mare profesionalism.
             </Text>
         </Info>
     </Rapid>

     

    <Inner>
         <Box>
            <Title>Instalații sanitare</Title>
            <Text>Desfundat toalete, coloane, sifoane de pardoseală, reparații coloane de apă rece și caldă, pluviale menajeră </Text>
         </Box>
         <Box>
            <Title>Instalare conducte gas și centrale</Title>
            <Text>Montaj centrală termică, montaj încălzire pardoseală, montaj calorifer. </Text>
         </Box>
         <Box>
            <Title>Instalare Boiler</Title>
            <Text>Instalarea și racordarea boilerelor de tip electric și termoelectric. </Text>
         </Box>
         <Box>
            <Title>Canalizare</Title>
            <Text>Desfundare canalizare, desfundare wc, desfundare chiuvetă, desfundare țevi și desfundare scurgere. </Text>
         </Box>
         <Box>
            <Title>Instalare Calorifere</Title>
            <Text>Instalarea și racordarea caloriferelor, instalarea de separatoare și robinete. </Text>
         </Box>
         <Box>
            <Title>Electrocasnice</Title>
            <Text>Racordare și instalare țevi/conducte pentru mașină de spălat și alte electrocasnice </Text>
         </Box>
         <Box>
            <Title>Și multe altele</Title>
            <Text>Sunați sau dați mesaj cu ce probleme aveți, Ninel va știi ce trebuie făcut. </Text>
         </Box>

    </Inner>
    </Wrapper>
)

}

export default Boxes